import 'swiper/css';
import 'swiper/css/navigation';

import '../sass/project.scss';
import ContactForm from './contact.jsx';
import {ProjectItem, ProductItem, PhotoGallery} from './items.jsx';
import { ThreeDSlider } from './homepage.jsx';

import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';


const Gallery =(props)=> {
    if(!props.categories) {
        return null;
    }

    const categories = JSON.parse(props.categories);
    const [categorizedItems, setCategorizedItems] = useState(JSON.parse(props.categorizedItems || '[]'));
    const [activeCatgory, setActiveCategory] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deviceType, setDeviceType] = useState(getDeviceType());
    const [selectedItem, setSelectedItem] = useState(null);
    const [divPosition, setDivPosition] = useState(0);
    const [cat, setCat] = useState(null);
    const [showDiv, setShowDiv] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showCategorized, setShowCategorized] = useState(false);
    const [details, setDetails] = useState(null);
    const [images, setImages] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);

    const itemRefs = useRef(data ? data.reduce((acc, d) => ({ ...acc, [d.id]: React.createRef() }), {}) : {}); // Use useRef here
    const location = useLocation();

    const [swiper, setSwiper] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const [activeAboutCategory, setActiveAboutCategory] = useState(null);


    useEffect(() => {
        if(props.categorizedItems && props.categorizedItems != '[]') {
            setShowCategorized(true);
        }

    }, [props.categorizedItems]);

     function getDeviceType() {
        const width = window.innerWidth;
        if (width <= 575) {
            return 'mobile'; // 3
        } else if (width <= 767) {
            return 'mobile-xl'; // 4
        } else if (width <= 1200) {
            return 'tablet'; // 5
        } else {
            return 'desktop'; // 8
        }

    }


    const calculateItemsPerRow = (deviceType) => {
        if (deviceType === 'desktop') {
            return 8;
        } else if (deviceType === 'mobile-xl') {
            return 4;
        } else if (deviceType === 'mobile') {
            return 3;
        } else if (deviceType === 'tablet') {
            return 5;
        }
    }


    const navigate = (id, category) => {
        const currentUrl = window.location.href.split('#')[0];
        const newUrl = `${currentUrl}#${id};${category}`;
        window.history.pushState({}, '', newUrl);
        if(swiper) {
            swiper.destroy();
            setSwiper(null);
        }
        if(thumbsSwiper) {
            thumbsSwiper.destroy();
            setThumbsSwiper(null);
        }
    }

    const handleResize = (() => {
        const newDeviceType = getDeviceType();
        console.log(newDeviceType);
        setDeviceType(newDeviceType);

        if(props.categorizedItems) {
            setCategorizedItems(JSON.parse(props.categorizedItems || []));
        }

        if (!data) {
            // Fetch data again if it's null
            if(props.initialItems) {
                setData(JSON.parse(props.initialItems));
            } else {
                setData([]);
            }
        }

        // Recalculate divPosition on resize
        const itemsPerRow = newDeviceType === 'mobile' ? 2 : newDeviceType === 'tablet' ? 3 : 6;
        if (data && selectedItem !== null) {
            const position = Math.ceil((selectedItem + 1) / itemsPerRow) * itemsPerRow - 1;
            if (position >= data.length) {
                setDivPosition(data.length - 1);
            } else {
                setDivPosition(position);
            }
        }
    });

    useEffect(() => {
        if(props.initialItems) {
            setData(JSON.parse(props.initialItems));
        } else {
            setData([]);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    useEffect(() => {
        setActiveCategory(parseInt(props.activeCategory));

        if(props.categories) {
            const cats = JSON.parse(props.categories);
            var active = cats.find(c => {
                return c.id === parseInt(props.activeCategory)
            });
            if (active) {
                setActiveAboutCategory(active.body);
            }
        }
    }, [props.activeCategory, props.categories]);

    useEffect( () => {

        async function fetchData(url) {
            setImageLoading(true);

            await axios.get(url)
                .then(response => {
                    setImages(response.data);
                    setImageLoading(false);
                }).catch(error => {
                    setImageLoading(false);
                });

        }
        let id;
        let cate

        if (location.hash) {
            id = location.hash.substring(1).split(';')[0];
            cate = location.hash.substring(1).split(';')[1];
        }

        if (id && data && cate) {
            let categorized = [];
            if(props.categorizedItems) {
                categorized = JSON.parse(props.categorizedItems || '[]');
            }

            let items = data;
            if(categorized.length > 0) {
                items = categorized.find(c => c.id == cate).items;
            }

            const position = items.findIndex(d => d.slug === id);
            if (position >= 0 && position < data.length) {
                setSelectedItem(id);
                const itemsPerRow = calculateItemsPerRow(deviceType);
                const rowNumber = Math.ceil((position + 1) / itemsPerRow);
                let lastIndexOfRow = rowNumber * itemsPerRow - 1;
                if (lastIndexOfRow >= items.length) {
                    lastIndexOfRow = items.length - 1;
                }

                setDivPosition(lastIndexOfRow);
                setDetails(items[position]);
                setShowDiv(true);
                if(categorized.length > 0) {
                    setCat(categorized.find(c => c.id == cate).category);
                }

                fetchData(items[position].images);

                const scroller = document.getElementById(`scrolling-id-${items[position].id}`);
                if(scroller) {
                    scroller.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            }
        }
    }, [location.hash, categorizedItems, data]);



    const loadImages = async (url, id)  => {
        await axios.get(url)
            .then(response => {
                const resp = response.data;
                setImages(resp);
                setImageLoading(false);
                const scroller = document.getElementById(`scrolling-id-${id}`);
                if(scroller) {
                    scroller.scrollIntoView({
                        behavior: 'smooth'
                    });
                }
            })
            .catch(error => {
                setImageLoading(false);
            });

    }

    const findPosition = (items, position) => {
        if (position >= 0 && position < items.length) {
            const itemsPerRow = calculateItemsPerRow(deviceType);
            const rowNumber = Math.ceil((position + 1) / itemsPerRow);
            let lastIndexOfRow = rowNumber * itemsPerRow - 1;
            if (lastIndexOfRow >= items.length) {
                lastIndexOfRow = items.length - 1;
            }
            setDivPosition(lastIndexOfRow);
            setShowDiv(true);
        }
    }

    const handleCategorizedClick = (d, c) => async () => {
        const id = d.slug;
        setSelectedItem(id);

        if(selectedItem === id) {
            closeDiv();
            return;
        }
        setShowContact(false);
        setImages(null);
        setDetails(d);
        setCat(c.category);

        const data = c.items;
        const position = data.findIndex(d => d.slug === id);

        findPosition(data, position);
        navigate(id, d.category_id);
        setImageLoading(true);
        await loadImages(d.images, d.id);
    }

    const handleClick = (d) => async () => {
        const id = d.slug;
        setSelectedItem(id);

        if (selectedItem === id) {
            closeDiv();
            return;
        }

        setShowContact(false);
        setImages(null);
        setDetails(d);
        const position = data.findIndex(d => d.slug === id);

        findPosition(data, position);
        navigate(id, d.category_id);
        setImageLoading(true);
        await loadImages(d.images, d.id);
    }


    const changeCategory = (id, url, api_url, body) => async () => {
        closeDiv();

        setActiveCategory(id);
        const newUrl = `${url}`;
        window.history.pushState({}, '', newUrl);

        setLoading(true);
        setSelectedItem(null);
        setDetails(null);
        setSwiper(null);
        setThumbsSwiper(null);
        setDivPosition(0);
        setImages(null);

        if(swiper) {
            swiper.destroy();
        }
        if(thumbsSwiper) {
            thumbsSwiper.destroy();
        }
        window.dispatchEvent(new Event('resize'));
        // Send the request

        await axios.get(api_url)
            .then(response => {
                const resp = response.data;

                if(resp.categories && resp.categories.length > 0) {
                    setShowCategorized(true);
                    setCategorizedItems(resp.categories);
                } else {
                    setShowCategorized(false);
                }

                const items = resp.items;

                itemRefs.current = items ? items.reduce((acc, d) => ({ ...acc, [d.id]: React.createRef() }), {}) : {};
                setData(items);
                handleResize();
                setLoading(false);
                setActiveAboutCategory(body);
                handleResize();
                closeDiv();
            })
            .catch(error => {
                setLoading(false);
                closeDiv();
            });


    }

    const closeDiv = () => {
        setShowDiv(false);
        setSelectedItem(null);
        setImages(null);
        if(swiper) {
            swiper.destroy();
        }
        if(thumbsSwiper) {
            thumbsSwiper.destroy();
        }
        const currentUrl = window.location.href.split('#')[0];
        window.history.replaceState({}, '', currentUrl);
    }

    const [zoomedImage, setZoomedImage] = useState(null);

    const showZoomedModal = (url) => () => {
        setZoomedImage(url);
    }

    const showContactForm = () => {
        setShowContact(!showContact);
    }

    const contactFormSubmitted = (result) => {
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const [showScrollButton, setShowScrollButton] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = document.documentElement.scrollTop;
        if(position > 700) {
            setShowScrollButton(true);
        } else {
            setShowScrollButton(false);
        }
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <>
            <div className="subcategories-buttons col-12 my-4" role="group">
                {categories && categories.map((category, index) => (
                    <span className={`btn btn-outline-light px-3 ${activeCatgory === category.id ? 'active' : ''}`}
                          key={index}
                          onClick={changeCategory(category.id, category.url, category.api_url, category.body)}>
                        {category.title}
                    </span>
                ))}
            </div>

            <div className={'modal fade'} tabIndex={-1} id={'contact-modal'} aria-hidden={true}
                 aria-labelledby={'contact-modal'}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{props.lang === 'lt' ? 'Užklausa' : 'Request'}</h5>
                            <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <ContactForm csrf={props.csrf} lang={props.lang} subject={details?.title}
                                         onClose={showContactForm} onSubmit={contactFormSubmitted}/>
                        </div>
                    </div>
                </div>
            </div>

            {showCategorized && (
                <div className={'row'}>
                    {categorizedItems.map((c, i) => {
                        return (
                            <>
                                <div className={'row'}>
                                    <div className={'col-12'}>
                                        <h1 className={'categorizedTitle'}>{c.category}</h1>
                                    </div>
                                </div>
                                <div className={`row g-3 g-md-4 gallery-items-wrapper ${loading ? 'loading' : ''}`} style={{marginBottom: '80px'}}>
                                    {c.items.map((d, index) => {
                                        return (
                                            <React.Fragment key={`item-${d.id}-${activeCatgory}`}>
                                                <div className={`col custom-col`} id={`scrolling-id-${d.id}`}
                                                     onClick={handleCategorizedClick(d, c)} ref={itemRefs[d.id]}>
                                                    <div
                                                        className={`gallery-item ${selectedItem === d.slug ? "active" : ''} animate__animated animate__fadeInUp animate__slower`}>
                                                        <div className="gallery-item-thumbnail">
                                                            <img src={d.thumbnail} alt="" loading={'lazy'}/>
                                                        </div>
                                                        <div className="gallery-item-title text-center">
                                                            <h6 className="mb-0">
                                                                {d.title}
                                                            </h6>
                                                        </div>
                                                        <div
                                                            className="stretched-link text-body-light text-decoration-none"></div>
                                                    </div>
                                                </div>
                                                {index === divPosition && c.category === cat && details && (
                                                    <>
                                                    {props.pageType === 'projects' && (
                                                        <div className={`col-12 slider-wrapper position-relative project-wrapper ${showDiv ? 'd-block' : 'd-none'} `}>
                                                            <ProjectItem details={details} images={images} imageLoading={imageLoading} lang={props.lang} close={closeDiv} showContactForm={showContactForm} showZoomedModal={showZoomedModal}/>
                                                        </div>
                                                    )}
                                                    {props.pageType === 'products' && (
                                                        <div className={`col-12 slider-wrapper position-relative product-wrapper ${showDiv ? 'd-block' : 'd-none'} `}>
                                                            <ProductItem details={details} images={images} imageLoading={imageLoading} lang={props.lang} close={closeDiv} showContactForm={showContactForm} showZoomedModal={showZoomedModal}/>
                                                        </div>
                                                    )}
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                            </>
                        );
                    })}
                </div>
            )}

            {!showCategorized && (
                <>
                    <div className={`row g-3 g-md-4 gallery-items-wrapper ${loading ? 'loading' : ''}`}>
                        {data && data.map((d, index) => {
                            return (
                                <React.Fragment key={`item-${d.id}-${activeCatgory}`}>
                                    <div className={`col custom-col`} id={`scrolling-id-${d.id}`}
                                         onClick={handleClick(d)} ref={itemRefs[d.id]}>
                                        <div
                                            className={`gallery-item ${selectedItem === d.slug ? "active" : ''} animate__animated animate__fadeInUp animate__slower`}>
                                            <div className="gallery-item-thumbnail">
                                                <img src={d.thumbnail} alt="" loading={'lazy'}/>
                                            </div>
                                            <div className="gallery-item-title text-center">
                                                <h6 className="mb-0">
                                                    {d.title}
                                                </h6>
                                            </div>
                                            <div className="stretched-link text-body-light text-decoration-none"></div>
                                        </div>
                                    </div>
                                    {index === divPosition && details && (
                                        <>
                                        {props.pageType === 'projects' && (
                                            <div className={`col-12 slider-wrapper position-relative project-wrapper ${showDiv ? 'd-block' : 'd-none'} `}>
                                                <ProjectItem details={details} images={images} imageLoading={imageLoading} lang={props.lang} close={closeDiv} showContactForm={showContactForm} showZoomedModal={showZoomedModal}/>
                                            </div>
                                        )}
                                        {props.pageType === 'products' && (
                                            <div className={`col-12 slider-wrapper position-relative product-wrapper ${showDiv ? 'd-block' : 'd-none'} `}>
                                                <ProductItem details={details} images={images} imageLoading={imageLoading} lang={props.lang} close={closeDiv} showContactForm={showContactForm} showZoomedModal={showZoomedModal}/>
                                            </div>
                                        )}
                                        </>
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </div>
                    {activeAboutCategory && (
                        <div className="col-12 mt-5">
                            <div className="border-radius-outline bg-input-dark px-3 py-4 p-lg-4"
                                 dangerouslySetInnerHTML={{__html: activeAboutCategory}}></div>
                        </div>
                    )}
                </>
            )}
            <div className={`row position-fixed bottom-0  ${showScrollButton ? 'd-sm-block d-md-none': 'd-none'}`} style={{left: '0px', right: '0px', zIndex: 3}} >
                <div className={'col-12 text-center mb-2'}>
                    <div className="d-grid">
                        <button className={'btn btn-light btn-lg rounded-0'} onClick={scrollToTop} style={{background: '#3B4040', border: '1px solid #3B4040', color: '#ffffff'}}>
                            {props.lang === 'lt' ? 'Grįžti į viršų' : 'Back to top'} <i className="bi bi-arrow-up"></i>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

const galleryElements = document.getElementsByClassName('gallery-element');

if (galleryElements.length > 0) {
    Array.from(galleryElements).forEach((el) => {
        const root = createRoot(el);
        root.render(
            <Router>
                <Gallery
                    categories={el.getAttribute('data-categories')}
                    activeCategory={el.getAttribute('data-active-category')}
                    initialItems={el.getAttribute('data-initial')}
                    categorizedItems={el.getAttribute('data-categorized-items')}
                    pageType={el.getAttribute('data-page-type')}
                    csrf={el.getAttribute('data-csrf')}
                    lang={el.getAttribute('data-lang')}
                />
            </Router>
        );
    });
}

const el = document.getElementById('gallery');

if (el != null) {
    const root = createRoot(el);
    root.render(
        <Router>
            <Gallery
                categories={el.getAttribute('data-categories')}
                activeCategory={el.getAttribute('data-active-category')}
                initialItems={el.getAttribute('data-initial')}
                categorizedItems={el.getAttribute('data-categorized-items')}
                pageType={el.getAttribute('data-page-type')}
                csrf={el.getAttribute('data-csrf')}
                lang={el.getAttribute('data-lang')}
            />
        </Router>
    );
}


const ServiceGallerySlider =(props)=> {
    const [zoomedImage, setZoomedImage] = useState(null);
    const showZoomedModal = (url) => () => {
        setZoomedImage(url);
    }

    return (
        <>
            <div
                className={`zoomed-modal ${zoomedImage ? '' : 'd-none'} d-flex justify-content-center align-items-center`}
                onClick={() => setZoomedImage(null)}>
                <img src={zoomedImage} alt=""/>
            </div>
            <PhotoGallery images={JSON.parse(props.images)} showZoomedModal={showZoomedModal}/>
        </>
    );
}

const imageGallery = document.getElementById('image-gallery');

if (imageGallery != null) {
    const root = createRoot(imageGallery);
    root.render(
        <Router>
            <ServiceGallerySlider
                images={imageGallery.getAttribute('data-images')}
                thumbnails={imageGallery.getAttribute('data-thumbnails')}
            />
        </Router>
    );
}

const ContactFormWrapper = (props) => {

    const contactFormSubmitted = (result) => {
    }

    const onClose = () => {
    }

    return (
        <>
            <ContactForm csrf={props.csrf} lang={props.lang} onSubmit={contactFormSubmitted} onClose={onClose}/>
        </>
    );
}


const contactForm = document.getElementById('contact-form');

if (contactForm != null) {
    const root = createRoot(contactForm);
    root.render(
        <Router>
            <ContactFormWrapper
                csrf={contactForm.getAttribute('data-csrf')}
                lang={contactForm.getAttribute('data-lang')}
            />
        </Router>
    );
}



const homeSlider = document.getElementById('homeslider');

if (homeSlider != null) {
    const root = createRoot(homeSlider);
    root.render(
        <Router>
            <ThreeDSlider />
        </Router>
    );
}

