import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Autoplay, Mousewheel, EffectFlip } from "swiper/modules";

const slides = [
  {
    "icon": "/static/images/icons/service.png",
    "title": "Vaizdo sprendimai:",
    "content": [
      "Platus LED ekranų pasirinkimas",
      "Meniniai ir komerciniai projekciniai sprendimai",
      "VJ paslaugos",
      "3D vizualizacijų kūrimas",
      "Profesionalios įrangos nuoma",
      "Viso daugiau nei 10000 m2 LED ekranų"
    ]
  },
  {
    "icon": "/static/images/icons/service.png",
    "title": "KTS sprendimai",
    "content": [
      "Profesionalios filmavimo paslaugos",
      "Kamerų režisavimas ir techninis valdymas",
      "Tiesioginės transliacijos renginių metu",
      "Turinio ir įrašų gamyba",
      "Įrangos nuoma",
      "Profesionalių operatorių komanda"
    ]
  },
  {
    "icon": "/static/images/icons/service.png",
    "title": "Virtualios realybės sprendimai:",
    "content": [
      "Augmented reality",
      "Virtual reality",
      "Mixed reality",
      "LED cave",
      "Motion capture",
    ]
  },
  {
    "icon": "/static/images/icons/service.png",
    "title": "Techninis projektavimas:",
    "content": [
      "Techninių brėžinių gamyba",
      "Maketų vizualizacijų kūrimas",
      "Techninių išteklių planavimas",
    ]
  },
  {
    "icon": "/static/images/icons/service.png",
    "title": "Garso sprendimai:",
    "content": [
      "Renginių įgarsinimas ir priežiūra",
      "Garso prietaisų instaliavimas ir valdymas renginių metu",
      "Garso Įrangos nuoma",
    ]
  },
  {
    "icon": "/static/images/icons/service.png",
    "title": "Šviesos sprendimai:",
    "content": [
      "Kūrybinių apšvietimo idėjų generavimas",
      "Vidaus ir lauko renginių apšvietimas bei priežiūra",
      "Šviesos prietaisų montavimas ir valdymas renginių metu",
      "Apšvietimo įrangos nuoma"
    ]
  },
  {
    "icon": "/static/images/icons/service.png",
    "title": "Konstrukciniai sprendimai:",
    "content": [
      "Konstrukcinės santvaros ir jų montavimas",
      "Automatizuoti sprendimai",
      "Garso Įrangos nuoma",
    ]
  }
];

const ThreeDSlider = () => {
  const swiperRef = useRef(null);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 1198);
  let scrollTimeout = null;

  useEffect(() => {
    const handleScroll = (event) => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
          if (event.deltaY > 0 || event.deltaX > 0) {
            swiperRef.current.swiper.slideNext();
          } else if (event.deltaY < 0 || event.deltaX < 0) {
            swiperRef.current.swiper.slidePrev();
          }
        }
      }, 25); // Wait for scrolling to stop before changing slide
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1198);
    };

    window.addEventListener("wheel", handleScroll);
    window.addEventListener("resize", handleResize);

    // Set initial state on mount
    handleResize();

    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("resize", handleResize);
      clearTimeout(scrollTimeout);
    };
  }, []);

  
    useEffect(() => {
      // Safari-specific fix: force repaint
      if (navigator.userAgent.includes("Safari") && !navigator.userAgent.includes("Chrome")) {
        setTimeout(() => {
          const wrapper = document.querySelector(".swiper-wrapper");
          if (wrapper instanceof HTMLElement) {
            if (wrapper) {
              wrapper.style.display = "none";
              wrapper.offsetHeight; // Trigger reflow
              wrapper.style.display = "flex";
            }
         }
        }, 100);
      }
    }, []);
  

  return (
    <div className="w-full flex justify-center items-center">
      <Swiper
        ref={swiperRef}
        {...(!isMobile && { effect: "coverflow" })}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={isMobile ? 1 : 4}
        loop={true}
        autoplay={isMobile}
        coverflowEffect={{ rotate: -10, stretch: -30, depth: 0, modifier: 3.5, slideShadows: false }}
        modules={[EffectCoverflow, Autoplay, Mousewheel]}
        className={`w-full ${isMobile ? "mob": ""}`}
        style={{ transform: 'translateZ(0px)' }} // Force hardware acceleration for Safari
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className={`rounded-xl shadow-lg p-5 ${isMobile ? "mob": ""}`} style={{ minHeight: '340px' }}>
            <img src={slide.icon} alt={`Slide ${index + 1}`} className="w-20 h-20" />
            <div >
              <h3 className="text-lg font-bold">{slide.title}</h3>
              <ul className="list-disc ml-6">
                {slide.content.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export { ThreeDSlider };